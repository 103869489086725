import React from "react";
import {bindActionCreators} from "redux";
import * as actions from "../actions";
import connect from "react-redux/es/connect/connect";
import Top from "./Top";
import Table from "./Table";
import * as consts from ".././consts";
import {Helmet} from "react-helmet";

class Categories extends React.Component {


    constructor(props) {
        super(props);
        this.loadMore = this.loadMore.bind(this);
        this.checkCategoryChange = this.checkCategoryChange.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.getCategory = this.getCategory.bind(this);
        this.checkCategoryChange();

        actions.log("categories constructor");
    }


    checkCategoryChange() {
        const {lastCategory, clearCategories, setLastCategory} = this.props;
        const {category, id} = this.props.match.params;
        const categoryAndValue = category + id;
        if (lastCategory !== categoryAndValue) {
            setLastCategory(categoryAndValue);
            clearCategories();
            this.render();
        }
    }

    loadMore(page) {
        const {loadByCategory} = this.props;
        const {category, id} = this.props.match.params;
        return loadByCategory(page, category, id);
    }

    getTitle(headDesc, noValue) {
        const {id} = this.props.match.params;
        let title = ""
        const cat = this.getCategory();

        if (headDesc) {
            title += cat.name.toLowerCase() + " - ";
        } else {
            title += cat.name + ": ";
        }
        for (let j in cat.value) {
            const v = cat.value[j];
            if (v.id === id) {
                if (cat.id !== "country") {
                    title += v.name.toLowerCase();
                } else {
                    title += v.name;
                }
                return title;
            }
        }
        return title;
    }

    getCategory() {
        const {category} = this.props.match.params;
        const categories = consts.categoriesMapping;
        for (let i in categories) {
            const cat = categories[i];
            if (cat.id === category) {
                return cat;
            }
        }
    }

    render() {

        const {personsByCategory, personsByCategoryMore} = this.props;


        const title = this.getTitle(false);

        const table = <Table persons={personsByCategory} loadMore={this.loadMore} hasMore={personsByCategoryMore}
                             title={title}/>;

        const headDescTitle=this.getTitle(true);
        const headDescription = "Kategoria: " + headDescTitle;
        const categoryName=this.getCategory().name.toLowerCase();
        const keywords=categoryName+" aktorów, "+ categoryName+" celebrytów, celebryci z podziałem na "+categoryName+", "+headDescTitle;
        return (
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={headDescription}/>
                    <meta name="keywords" content={keywords}/>
                </Helmet>
                <Top/>
                {table}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        personsByCategory: state.personsByCategory,
        personsByCategoryMore: state.personsByCategoryMore,
        lastCategory: state.lastCategory
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        loadByCategory: actions.loadByCategory,
        clearCategories: actions.clearCategories,
        setLastCategory: actions.setLastCategory
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);