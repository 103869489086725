import React from 'react';
import {Link} from 'react-router-dom';
import * as actions from "../actions";
import {connect} from "react-redux";
import * as consts from "../consts";
import {bindActionCreators} from "redux";

class PodiumItem extends React.Component {
    constructor(props) {
        super(props);
        this.addDefaultSrc = this.addDefaultSrc.bind(this);
        this.pTag = React.createRef();
    }

    addDefaultSrc(ev) {
        const {person} = this.props;
        actions.setDefaultUrlImg(person, ev);
    }

    componentDidMount(){
        const {setPodiumDescriptionHeight,podiumDescriptionHeight} = this.props;
        const pTagHeight=this.pTag.current.clientHeight;
        if(pTagHeight>podiumDescriptionHeight) {
            setPodiumDescriptionHeight(pTagHeight);
        }
    }

    render() {
        const {person, color, number, virtualListWidth, podiumDescriptionHeight} = this.props;

        const url = actions.getPersonUrl(person);
        const urlImg = actions.getPersonUrlImg(person, false, virtualListWidth, false);
        const personText = actions.getPersonKnownName(person);
        const altImg= actions.getAltImg(personText,person.profilePictureSource);

        const classNameMedal = "fas fa-medal fa-3x " + color;
        const classNameCircle = "fas fa-circle fa-2x " + color;
        const classNameNumber = "fa-layers-text fa-inverse";
        let classNameForDiv="col p-0";
        if(color==='gold-icon-color'){
            classNameForDiv+=" mx-1";
        }

        let stylePTag={};
        if(podiumDescriptionHeight>consts.descriptionHeightDefault){
            stylePTag={height:podiumDescriptionHeight};
        }

        return (
            <div className={classNameForDiv}>
                <Link to={url} className="list-group-item link-image">
                    <div className="image-container">
                        <img onError={this.addDefaultSrc} src={urlImg} className="img-fluid" alt={altImg}/>
                        <div className="content">
                            <p style={stylePTag} ref={this.pTag}>{personText}</p>
                        </div>
                    </div>
                </Link>

                <span className="fa-layers fa-fw medal">
                    <i className={classNameMedal}/>
                    <i className={classNameCircle} data-fa-transform="right-4 down-4"/>
                    <span className={classNameNumber} data-fa-transform="right-13.5 down-8">{number}</span>
                </span>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        virtualListWidth: state.virtualListWidth,
        podiumDescriptionHeight: state.podiumDescriptionHeight
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setPodiumDescriptionHeight: actions.setPodiumDescriptionHeight
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PodiumItem);
