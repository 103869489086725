import React from "react";
import {bindActionCreators} from "redux";
import * as actions from "../actions";
import connect from "react-redux/es/connect/connect";
import Top from "./Top";
import Table from "./Table";
import {Helmet} from "react-helmet";

class Ranking extends React.Component {

    render() {

        const {ranking, loadRanking, rankingMore} = this.props;

        return (
            <div>
                <Helmet>
                    <title>Top 10</title>
                    <meta name="description" content="Ranking najpopularniejszych celebrytów, aktorów i znanych ludzi" />
                    <meta name="keywords" content="ranking celebrytów, ranking aktorów, top 10 celebrytów" />
                </Helmet>
                <Top/>
                <Table persons={ranking} loadMore={loadRanking} hasMore={rankingMore} title="Top 10"/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ranking: state.ranking,
        rankingMore: state.rankingMore
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        loadRanking: actions.loadRanking
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Ranking);