import React from "react";
import {bindActionCreators} from "redux";
import * as actions from "../actions";
import connect from "react-redux/es/connect/connect";
import Top from "./Top";
import Table from "./Table";
import {Helmet} from "react-helmet";

class Trends extends React.Component {

    render() {
        const {trends, loadTrends, trendsMore} = this.props;
        return (
            <div>
                <Helmet>
                    <title>Top 24h</title>
                    <meta name="description" content="Trendy popularności celebrytów ostatnie 24h" />
                    <meta name="keywords" content="trendy popularności aktorów, trendy popularności celebrytów, top 24h znanych ludzi" />
                </Helmet>
                <Top/>
                <Table persons={trends} loadMore={loadTrends} hasMore={trendsMore} title="Top 24h"/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        trends: state.trends,
        trendsMore: state.trendsMore
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        loadTrends: actions.loadTrends
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Trends);