import React from 'react';
import {Link} from 'react-router-dom';
import * as actions from "../actions";

class SearchRow extends React.Component {

    render() {
        const person = this.props.person;

        const url = actions.getPersonUrl(person);
        let personText = actions.getPersonKnownName(person);
        return (
            <Link to={url} className="list-group-item list-group-item-action">
                {personText}
            </Link>
        )
    }
}


export default SearchRow;
