import React from 'react';
import {Link} from 'react-router-dom';
import * as consts from ".././consts";
import Drawer from 'react-drag-drawer'
import {bindActionCreators} from "redux";
import * as actions from "../actions";
import {connect} from "react-redux";

class CategoriesMenu2 extends React.Component {

    constructor(props) {
        super(props);
        this.getTags = this.getTags.bind(this);
    }

    getTags(options, name) {
        const {toggleCategoriesMenu} = this.props;
        let tags = options.map(option => {
                const url = "/categories/" + name + "/" + option.id;
                return <li key={url}><Link to={url} onClick={toggleCategoriesMenu}>{option.name}</Link></li>
            }
        );
        return tags;
    }


    render() {
        let columns = consts.categoriesMapping.map(category => {
            const href = "#" + category.id;
            const liTags = this.getTags(category.value, category.id);
            let ulClassNames = "multi-column-dropdown collapse";
            let aClassName = "collapsed categoriesCollapseLink";
            if (category.id === consts.categoriesMapping[0].id || category.id === consts.categoriesMapping[1].id) {
                ulClassNames += " show";
                aClassName = "categoriesCollapseLink";
            }

            return <div className="col-sm" key={category.value + category.id}>

                <h5>
                    <a href={href} data-toggle="collapse" className={aClassName}>{category.name}</a>
                </h5>
                <ul id={category.id} className={ulClassNames}>
                    {liTags}
                </ul>
            </div>
        });

        const {categoriesMenuOpen, toggleCategoriesMenu} = this.props;

        return (
            <Drawer modalElementClass="categoriesMenu"
                    open={categoriesMenuOpen}
                    onRequestClose={toggleCategoriesMenu}
                    direction='left'>

                {columns}
            </Drawer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        categoriesMenuOpen: state.categoriesMenuOpen
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleCategoriesMenu: actions.toggleCategoriesMenu
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesMenu2);
