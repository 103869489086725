import React from 'react';
import InfiniteScroll from "react-infinite-scroller";
import TableRow from "./TableRow";

class Table extends React.Component {

    render() {
        const {loadMore, hasMore, persons, title} = this.props;
        let rows = persons.map(person => <TableRow key={person._links.self.href} person={person}/>
        );
        return (
            <div className="mainPart text-left paddingMain minHeight">
                <div className="container">
                    <h2 className="h2 text-center">{title}
                    </h2>

                    <InfiniteScroll
                        pageStart={-1}
                        threshold={0}
                        loadMore={loadMore}
                        hasMore={hasMore}
                        loader={<div className="loader" key={0}>Loading ...</div>}>
                        <table className="table ">
                            <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Odwiedziny 24H</th>
                                <th scope="col">Ranking</th>
                                {/*<th scope="col">Zawód</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                    <br/>
                </div>
            </div>
        )
    }
}


export default Table;
