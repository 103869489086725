import React from 'react';
import HorizontalMenuItem from "./HorizontalMenuItem";
import connect from "react-redux/es/connect/connect";
import VirtualList from 'react-tiny-virtual-list';

class HorizontalMenu extends React.Component {
    constructor(props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
    }


    renderItem({index, style}) {
        const {trendsTop} = this.props;
        const person = trendsTop[index + 3];
        return (
            <HorizontalMenuItem key={person.id} person={person} style={style}/>
        );

    }


    render() {
        const {trendsTop, virtualListWidth, virtualListItemSize} = this.props;

        let itemCount = 0;
        if (trendsTop.length > 0) {
            itemCount = trendsTop.length - 3;
        }

        return (

            <div className="row justify-content-center">
                <VirtualList
                    width={virtualListWidth}
                    height={virtualListItemSize + 10}
                    itemCount={itemCount}
                    itemSize={virtualListItemSize} // Also supports variable heights (array or function getter)
                    renderItem={this.renderItem}
                    scrollDirection={'horizontal'}
                    scrollOffset={400}
                />
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        trendsTop: state.trendsTop,
        virtualListWidth: state.virtualListWidth,
        virtualListItemSize: state.virtualListItemSize
    };
};


export default connect(mapStateToProps)(HorizontalMenu);
