import React from "react";
import {bindActionCreators} from "redux";
import * as actions from "../actions";
import connect from "react-redux/es/connect/connect";
import PodiumItem from "./PodiumItem";
import Top from "./Top";
import {Link} from "react-router-dom";
import HorizontalMenu from "./HorizontalMenu";
import NewsMenu from "./NewsMenu";
import {Helmet} from "react-helmet";
import CookieDisclaimer from 'react-cookie-disclaimer';

class Home extends React.Component {


    componentDidMount() {
        const {loadTrendsTop, setNewsForToday} = this.props;
        loadTrendsTop();
        setNewsForToday();
    }

    render() {
        const {trendsTop} = this.props;

        let trends = [];
        if (trendsTop.length > 3) {
            trends[0] = <PodiumItem key={trendsTop[1]._links.self.href} person={trendsTop[1]} color="silver-icon-color"
                                    number="2"/>;
            trends[1] = <PodiumItem key={trendsTop[0]._links.self.href} person={trendsTop[0]} color="gold-icon-color"
                                    number="1"/>;
            trends[2] = <PodiumItem key={trendsTop[2]._links.self.href} person={trendsTop[2]} color="brown-icon-color"
                                    number="3"/>;
        }


        return (
            <div>
                <Helmet>
                    <title>kto z kim randkuje - związki gwiazd, celebrytów i znanych osób</title>
                    <meta name="description" content="kto z kim randkuje? Historia związków gwiazd, celebrytów, sportowców i znanych osób" />
                    <meta name="keywords" content="związdki gwiazd, związdki celebrytów, historia związków gwiazd, historia związków celebrytów, historia małżeństw gwiazd, historia małżeństw celebrytów, kto z kim randkował?, kto z kim chodził?" />
                </Helmet>
                <Top/>
                <div className="mainPart text-center paddingMain">
                    <div className="container">
                        <h2 className="h2">
                            <Link to="/trends" className="text-center">Top 24h</Link>
                            <a className="float-right align-bottom  moreLink" href="/trends">
                                Więcej >>>
                            </a>
                        </h2>
                        <div className="row">
                            {trends}
                        </div>
                        <br/>
                        <br/>
                        <HorizontalMenu/>
                        <br/>
                        <br/>
                    </div>
                </div>

                <div className="lightBackground text-center paddingMain">
                    <div className="container">
                        <h2 className="h2">Najnowsze wydarzenia</h2>
                        <NewsMenu/>
                    </div>
                </div>
                <CookieDisclaimer
                    background='#808080'
                    bottomPosition={true}
                    closeIconSize={30}
                    closeIconPositionTop={false}
                    color='#fff'
                    text='Ta strona używa cookie w celu personalizacji reklam, udostępniania funkcji mediów społecznościowych oraz analizowania ruchu w Internecie. Kontynuując zgadzasz się na użycie tej technologii.'
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        trendsTop: state.trendsTop
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        loadTrendsTop: actions.loadTrendsTop,
        setNewsForToday: actions.setNewsForToday,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);