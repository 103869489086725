
import React from "react";
import Top from "./Top";
import {Helmet} from "react-helmet";

class Impressum extends React.Component {

    render() {
        return (
            <div>
                <Helmet>
                    <title>Impressum</title>
                    <meta name="description" content="Kontakt, impressum" />
                    <meta name="keywords" content="Kontakt, impressum" />
                </Helmet>
                <Top/>
                <div className="mainPart text-left paddingMain minHeight">
                    <div className="container">
                        <div className="ml-2">
                            <p>Email: kontakt@ktozkimrandkuje.pl</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



export default Impressum;