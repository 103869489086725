import React from 'react';
import {connect} from "react-redux";
import RelationshipPeriodRow from "./RelationshipPeriodRow";
import * as actions from "../actions";

class RelationshipPeriodTable extends React.Component {

    getPictureSource(){
        const {relationship} = this.props;
        if(relationship.flatProfilePictureSource){
            return             <tr>
                <td>Źródło zdjęć</td>
                <td>{relationship.flatProfilePictureSource}</td>
                <td></td>
            </tr>;
        }else{
            return null;
        }
    }

    render() {
        actions.log("RelationshipPeriodTable");
        const {relationship} = this.props;
        let rows = relationship.relationshipPeriods.map(period => {
                return <RelationshipPeriodRow key={period.id} period={period}/>
            }
        );
        const pictureSource=this.getPictureSource();
        if(pictureSource){
            rows.push(pictureSource);
        }
        return (
            <div className="paddingMain">
                <div className="container">
                    <h3 className="header3">Okresy związku:</h3>
                    <table className="table ">
                        <tbody>
                        <tr>
                            <th>Status</th>
                            <th>Początek</th>
                            <th>Koniec</th>
                        </tr>
                        {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        relationship: state.relationship
    };
};


export default connect(mapStateToProps)(RelationshipPeriodTable)
