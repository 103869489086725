import React from 'react';
import * as actions from "../actions";
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import * as consts from "../consts";
import {bindActionCreators} from "redux";

class HorizontalMenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.addDefaultSrc = this.addDefaultSrc.bind(this);
        this.pTag = React.createRef();
    }

    addDefaultSrc(ev) {
        const {person} = this.props;
        actions.setDefaultUrlImg(person, ev);
    }

    componentDidMount(){
        const {setHorizontalMenuDescriptionHeight,horizontalMenuDescriptionHeight} = this.props;
        const pTagHeight=this.pTag.current.clientHeight;
        if(pTagHeight>horizontalMenuDescriptionHeight) {
            setHorizontalMenuDescriptionHeight(pTagHeight);
        }
    }

    render() {
        const {person, style, virtualListWidth,horizontalMenuDescriptionHeight} = this.props;
        const url = actions.getPersonUrl(person);
        const urlImg = actions.getPersonUrlImg(person, false, virtualListWidth, true);
        const personText = actions.cutPersonName(actions.getPersonKnownName(person),20);
        const altImg= actions.getAltImg(personText,person.profilePictureSource);

        let stylePTag={};
        if(horizontalMenuDescriptionHeight>consts.descriptionHeightDefault){
            stylePTag={height:horizontalMenuDescriptionHeight};
        }

        return (
            <div style={style} className="horizontalMenuItem">
                <Link to={url} className="list-group-item link-image">
                    <div className="image-container">
                        <img onError={this.addDefaultSrc}
                             src={urlImg} className="img-fluid horizontalMenuImage"
                             alt={altImg}/>
                        <div className="content">
                            <p style={stylePTag} ref={this.pTag}>{personText}</p>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        virtualListWidth: state.virtualListWidth,
        horizontalMenuDescriptionHeight: state.horizontalMenuDescriptionHeight
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setHorizontalMenuDescriptionHeight: actions.setHorizontalMenuDescriptionHeight
    }, dispatch);
};

export default connect(mapStateToProps,mapDispatchToProps)(HorizontalMenuItem);