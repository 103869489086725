import * as consts from "./consts";

const initialState = {
    person: {},
    relationships: [],
    relationship: {},
    rankingTop: [],
    trendsTop: [],
    searchSuggestions: [],
    searchValue: "",
    trendsMore: true,
    trends: [],
    rankingMore: true,
    ranking: [],
    personsByCategoryMore: true,
    personsByCategory: [],
    lastCategory: "",
    categoriesMenuOpen: false,
    virtualListWidth: 960,
    virtualListItemSize: 180,
    newsItemSize: 450,
    newsItemsHeight: 305,
    newsForToday: [],
    popupVisible: false,
    newsDescriptionHeight: consts.descriptionHeightDefault,
    horizontalMenuDescriptionHeight: consts.descriptionHeightDefault,
    podiumDescriptionHeight: consts.descriptionHeightDefault
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case consts.LOAD_PERSON: {
            return {...state, person: action.payload};
        }
        case consts.LOAD_RELATIONSHIPS: {
            return {...state, relationships: action.payload};
        }
        case consts.LOAD_RELATIONSHIP: {
            return {...state, relationship: action.payload};
        }
        case consts.LOAD_RANKING_TOP: {
            return {...state, rankingTop: action.payload};
        }
        case consts.LOAD_TRENDS_TOP: {
            return {...state, trendsTop: action.payload};
        }
        case consts.LOAD_TRENDS: {
            return {...state, trends: state.trends.concat(action.payload)};
        }
        case consts.SET_TRENDS_MORE: {
            return {...state, trendsMore: action.payload};
        }
        case consts.LOAD_RANKING: {
            return {...state, ranking: state.ranking.concat(action.payload)};
        }
        case consts.SET_RANKING_MORE: {
            return {...state, rankingMore: action.payload};
        }
        case consts.LOAD_BY_CATEGORY: {
            return {...state, personsByCategory: state.personsByCategory.concat(action.payload)};
        }
        case consts.CLEAR_BY_CATEGORY: {
            return {...state, personsByCategory: action.payload};
        }
        case consts.SET_BY_CATEGORY_MORE: {
            return {...state, personsByCategoryMore: action.payload};
        }
        case consts.SET_LAST_CATEGORY: {
            return {...state, lastCategory: action.payload};
        }
        case consts.LOAD_SEARCH_SUGGESTIONS: {
            return {...state, searchSuggestions: action.payload};
        }
        case consts.SET_SEARCH_VALUE: {
            return {...state, searchValue: action.payload};
        }
        case consts.SET_CATEGORIES_MENU_OPEN: {
            return {...state, categoriesMenuOpen: !state.categoriesMenuOpen};
        }
        case consts.SET_VIRTUAL_LIST_WIDTH: {
            return {...state, virtualListWidth: action.payload};
        }
        case consts.SET_VIRTUAL_ITEM_SIZE: {
            return {...state, virtualListItemSize: action.payload};
        }
        case consts.SET_NEWS_ITEM_SIZE: {
            return {...state, newsItemSize: action.payload};
        }
        case consts.SET_NEWS_ITEMS_HEIGHT: {
            return {...state, newsItemsHeight: action.payload};
        }
        case consts.SET_NEWS_FOR_TODAY: {
            return {...state, newsForToday: action.payload};
        }
        case consts.SET_POPUP_VISIBLE: {
            return {...state, popupVisible: action.payload};
        }
        case consts.SET_NEWS_DESCRIPTION_HEIGHT: {
            return {...state, newsDescriptionHeight: Math.max(state.newsDescriptionHeight ,action.payload)};
        }
        case consts.SET_HORIZONTAL_MENU_DESCRIPTION_HEIGHT: {
            return {...state, horizontalMenuDescriptionHeight: Math.max(state.horizontalMenuDescriptionHeight ,action.payload)};
        }
        case consts.SET_PODIUM_DESCRIPTION_HEIGHT: {
            return {...state, podiumDescriptionHeight: Math.max(state.podiumDescriptionHeight ,action.payload)};
        }
        default:
            return state;
    }

}

export default reducer;

