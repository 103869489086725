export const LOAD_PERSON = 'LOAD_PERSON';
export const LOAD_RELATIONSHIPS = 'LOAD_RELATIONSHIPS';
export const LOAD_RELATIONSHIP = 'LOAD_RELATIONSHIP';
export const LOAD_RANKING_TOP = 'LOAD_RANKING_TOP';
export const LOAD_TRENDS_TOP = 'LOAD_TRENDS_TOP';
export const LOAD_TRENDS = 'LOAD_TRENDS';
export const SET_TRENDS_MORE = 'SET_TRENDS_MORE';
export const LOAD_RANKING = 'LOAD_RANKING';
export const SET_RANKING_MORE = 'SET_RANKING_MORE';
export const LOAD_BY_CATEGORY = 'LOAD_BY_CATEGORY';
export const SET_BY_CATEGORY_MORE = 'SET_BY_CATEGORY_MORE';
export const LOAD_SEARCH_SUGGESTIONS = 'LOAD_SEARCH_SUGGESTIONS';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_LAST_CATEGORY = 'SET_LAST_CATEGORY';
export const CLEAR_BY_CATEGORY = 'CLEAR_BY_CATEGORY';
export const SET_CATEGORIES_MENU_OPEN = 'SET_CATEGORIES_MENU_OPEN';
export const SET_VIRTUAL_LIST_WIDTH = 'SET_VIRTUAL_LIST_WIDTH';
export const SET_VIRTUAL_ITEM_SIZE = 'SET_VIRTUAL_ITEM_SIZE';
export const SET_NEWS_ITEM_SIZE = 'SET_NEWS_ITEM_SIZE';
export const SET_NEWS_ITEMS_HEIGHT = 'SET_NEWS_ITEMS_HEIGHT';
export const SET_NEWS_FOR_TODAY = 'SET_NEWS_FOR_TODAY';
export const SET_POPUP_VISIBLE = 'SET_POPUP_VISIBLE';
export const SET_NEWS_DESCRIPTION_HEIGHT = 'SET_NEWS_DESCRIPTION_HEIGHT';
export const SET_HORIZONTAL_MENU_DESCRIPTION_HEIGHT = 'SET_HORIZONTAL_MENU_DESCRIPTION_HEIGHT';
export const SET_PODIUM_DESCRIPTION_HEIGHT = 'SET_PODIUM_DESCRIPTION_HEIGHT';


export const colHomepageSum = 4;// ref .colHomepage
export const virtualListWidthL = 960 - colHomepageSum;
export const virtualListWidthM = 720 - colHomepageSum;
export const virtualListWidthS = 540 - colHomepageSum;

export const descriptionHeightDefault = 20;

export const eyeColor = [
    {id: "BROWN", name: "Brązowe"},
    {id: "BLUE", name: "Niebieskie"},
    {id: "GRAY", name: "Szare"},
    {id: "GREEN", name: "Zielone"},
    {id: "AMBER", name: "Bursztynowe"},
    {id: "HAZEL", name: "Orzechowe"},
    {id: "UNKNOWN", name: "Nieznane"}
];

export const status = [
    {id: "MARRIED", name: "Małżeństwo"},
    {id: "ENGAGEMENT", name: "Narzeczeństwo"},
    {id: "RELATIONSHIP", name: "Związek"},
    {id: "ENCOUNTER", name: "Randkowanie"},
    {id: "SINGLE", name: "Nieznany"},
    {id: "DIVORCE", name: "Rozwód"},
];

export const hairColor = [
    {id: "BLACK", name: "Czarny"},
    {id: "BLONDE", name: "Blond"},
    {id: "BROWN_DARK", name: "Ciemny brąz"},
    {id: "BROWN_LIGHT", name: "Jasny brąz"},
    {id: "GREY", name: "Siwy"},
    {id: "RED", name: "Czerwony"},
    {id: "UNKNOWN", name: "Nieznane"}
];

export const sex = [
    {id: "MALE", name: "Mężczyzna"},
    {id: "FEMALE", name: "Kobieta"},

];

export const countries = [
    {id: "PL", name: "Polska"},
    {id: "US", name: "USA"},
    {id: "FR", name: "Francja"},
    {id: "CA", name: "Kanada"},
    {id: "DE", name: "Niemcy"},
    {id: "IT", name: "Włochy"},
    {id: "AU", name: "Australia"},
];

export const age = [
    {id: "0", name: "<10"},
    {id: "10", name: "10-20"},
    {id: "20", name: "20-30"},
    {id: "30", name: "30-40"},
    {id: "40", name: "40-50"},
    {id: "50", name: "50-60"},
    {id: "60", name: "60-70"},
    {id: "70", name: "70-80"},
    {id: "80", name: "80-90"},
    {id: "90", name: "90-100"},
    {id: "100", name: ">100"},
];

export const zodiac = [
    {id: "ARIES", name: "Baran"},
    {id: "TAURUS", name: "Byk"},
    {id: "GEMINI", name: "Bliźnięta"},
    {id: "CANCER", name: "Rak"},
    {id: "LEO", name: "Lew"},
    {id: "VIRGO", name: "Panna"},
    {id: "LIBRA", name: "Waga"},
    {id: "SCORPIO", name: "Skorpion"},
    {id: "SAGITTARIUS", name: "Strzelec"},
    {id: "CAPRICORN", name: "Koziorożec"},
    {id: "AQUARIUS", name: "Wodnik"},
    {id: "PISCES", name: "Ryby"}
];

export const sexuality = [
    {id: "UNKNOWN", name: "Nieznana"},
    {id: "HETERO", name: "Hetero"},
    {id: "BISEXUAL", name: "Bi"},
    {id: "HOMOSEXUAL", name: "Homo"}
];

export const occupation = [
    {id: "UNKNOWN", name: "Nieznany"},
    {id: "OTHER", name: "Inne"},
    {id: "ACTOR", name: "Aktor", femaleName: "Aktorka"},
    {id: "SINGER", name: "Piosenkarz", femaleName: "Piosenkarka"},
    {id: "BUSINESSMAN", name: "Biznesmen", femaleName: "Bizneswoman"},
    {id: "SPORTSMAN", name: "Sportowiec"},
    {id: "CHEF", name: "Szef kuchni"},
    {id: "MODEL", name: "Model", femaleName: "Modelka"},
    {id: "JOURNALIST", name: "Dziennikarz", femaleName: "Dziennikarka"},
    {id: "ROYALTY", name: "Rodzina królewska"},
    {id: "PORN_STAR", name: "Gwiazda porno"},
    {id: "WRITER", name: "Pisarz", femaleName: "Piosenkarka"},
    {id: "DESIGNER", name: "Projektant"},
    {id: "CELEBRITY", name: "Celebryta"},
    {id: "TELEVISION_PRESENTER", name: "Prezenter telewizyjny"},
    {id: "FILM_PRODUCER", name: "Producent filmowy"},
    {id: "COSTUME_DESIGNER", name: "Kostiumolog"},
    {id: "LAWYER", name: "Prawnik"},
    {id: "MUSICIAN", name: "Muzyk"},
    {id: "HUMORIST", name: "Komik"},
    {id: "CRIMINAL", name: "Kryminalista"},
    {id: "ARCHITECT", name: "Atchitekt"},
    {id: "FILM_DIRECTOR", name: "Reżyser"},
    {id: "GLAMOUR_MODEL", name: "Fotomodel", femaleName: "Fotomodelka"},
    {id: "SCREENWRITER", name: "Scenarzysta"},
    {id: "DANCER", name: "Tancerz", femaleName: "Tancerka"},
    {id: "PHOTOGRAPHER", name: "Fotograf"},
    {id: "TENNIS_PLAYER", name: "Tenisista"},
    {id: "FOOTBALL_PLAYER", name: "Piłkarz"},
    {id: "HOCKEY_PLAYER", name: "Hokeista"},
    {id: "BASKETBALL_PLAYER", name: "Koszykarz"},
    {id: "VOLLEYBALL_PLAYER", name: "Siatkarz"},
    {id: "GOLFER", name: "Golfista"},
    {id: "MMA_FIGHTER", name: "Zawodnik MMA"},
    {id: "BOXER", name: "Bokser"},
    {id: "POLITICIAN", name: "Polityk"},
    {id: "MILITARY", name: "Wojskowy"},
    {id: "MUSIC_MANAGER", name: "Menadżer muzyczny"},
    {id: "DOCTOR", name: "Lekarz"},
    {id: "STYLIST", name: "Stylista", femaleName: "Stylistka"},
    {id: "IT_SPECIALIST", name: "Informatyk"},
    {id: "BLOGGER", name: "Bloger"},
    {id: "PILOT", name: "Pilot"},
    {id: "RAPPER", name: "Raper"},
    {id: "MANAGER", name: "Manager"},
    {id: "THEATROLOGIST", name: "Teatrolog"},
    {id: "OPTICIAN", name: "Optyk"},
    {id: "CINEMATOGRAPHER", name: "Operator filmowy"},
    {id: "BODYBUILDER", name: "Kulturysta"},
    {id: "TRANSLATOR", name: "Tłumacz"},
    {id: "STOMATOLOGIST", name: "Stomatolog"},
    {id: "SATIRIST", name: "Satyryk"},
    {id: "COMPOSER", name: "Kompozytor"},
    {id: "CAMERA_OPERATOR", name: "Operator kamery"}
];


export const categoriesMapping = [
    {id: "sex", name: "Płeć", value: sex},
    {id: "status", name: "Etap związku", value: status},
    {id: "eyeColor", name: "Kolor oczu", value: eyeColor},
    {id: "country", name: "Narodowość", value: countries},
    {id: "hairColor", name: "Kolor włosów", value: hairColor},
    {id: "age", name: "Wiek", value: age},
    {id: "zodiac", name: "Znak zodiaku", value: zodiac}
];

export const religion = [
    {id: "UNKNOWN", name: "Nieznana"},
    {id: "CATHOLIC", name: "Katolicyzm"},
    {id: "PROTESTANTISM", name: "Protestantyzm"},
    {id: "JUDAISM", name: "Judaizm"},
    {id: "ISLAM", name: "Islam"},
    {id: "HINDUISM", name: "Hinduizm"},
    {id: "BUDDHISM", name: "Buddyzm"},
    {id: "ATHEISM", name: "Ateizm"},
    {id: "AGNOSTICISM", name: "Agnostycyzm"},
    {id: "BAPTISM", name: "Baptyzm"},
    {id: "CHRISTIANITY", name: "Chrześcijaństwo"},
    {id: "SCIENTOLOGIST", name: "Scjentologia"},
    {id: "LUTHERANISM", name: "Luteranizm"}
];

export const personAttributes = [
    {id: "ranking", name: "Ranking"},
    {id: "firstName", name: "Imię"},
    {id: "middleName", name: "Drugie imię"},
    {id: "lastName", name: "Nazwisko"},
    {id: "maidenName", name: "Nazwisko panieńskie", type: "text"},
    {id: "realName", name: "Prawdziwe imię i nazwisko", type: "text"},
    {id: "sex", name: "Płeć", value: sex},
    {id: "status", name: "Etap związku", value: status},
    {id: "country", name: "Narodowość", value: status},
    {id: "description", name: "Opis"},
    {id: "birthPlace", name: "Miejsce urodzenia"},
    {id: "age", name: "Wiek", value: age},
    {id: "birthday", name: "Data urodzin"},
    {id: "zodiac", name: "Znak zodiaku", value: zodiac},
    {id: "deathday", name: "Data śmierci"},
    {id: "deathPlace", name: "Miejsce śmierci"},
    {id: "height", name: "Wzrost"},
    {id: "weight", name: "Waga"},
    {id: "religion", name: "Religia", value: religion},
    {id: "eyeColor", name: "Kolor oczu", value: eyeColor},
    {id: "hairColor", name: "Kolor włosów", value: hairColor},
    {id: "sexuality", name: "Orientacja", value: sexuality},
    {id: "occupation", name: "Zawód", value: occupation},
    {id: "occupationText", name: "Zawód szczegóły"},
    {id: "nickName", name: "Pseudonim"},
    // {id: "parents", name: "Rodzice"},
    // {id: "children", name: "Dzieci"},
    {id: "officialWebsite", name: "Strona internetowa"},
    {id: "instagram", name: "Instagram", link: true},
    // {id: "filmography", name: "Filmografia"},
    // {id: "relationships", name: "Związki"},
    // {id: "statusRumor", name: ""},
    // {id: "scoresLast24h", name: ""},
];