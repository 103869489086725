import React from 'react';
import * as actions from "../actions";
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as consts from "../consts";

class NewsMenuItem extends React.Component {

    constructor(props) {
        super(props);
        this.addDefaultSrc = this.addDefaultSrc.bind(this);
        this.pTag = React.createRef();
    }

    addDefaultSrc(ev) {
        const {news,index} = this.props;
        if (news.personId) {
            const person = {sex: news.personSex};
            actions.setDefaultUrlImg(person, ev, true, index, news.latestEventType);
        } else {
            actions.setDefaultRelationshipUrlImg(news.relationship, ev, news.latestEventType);
        }
    }

    componentDidMount() {
        const {setNewsDescriptionHeight, newsDescriptionHeight} = this.props;
        const pTagHeight = this.pTag.current.clientHeight;
        if (pTagHeight > newsDescriptionHeight) {
            setNewsDescriptionHeight(pTagHeight);
        }
    }


    render() {
        const {news, style, virtualListWidth, newsDescriptionHeight,index} = this.props;
        let url = "";
        let urlImg = "";
        if (news.personId) {
            const person = {
                id: news.personId, popularName: news.names, sex: news.sex,
                flatProfilePicture: news.flatProfilePicture
            };
            url = actions.getPersonUrl(person);
            urlImg = actions.getPersonUrlImg(person, true, virtualListWidth, true, index, news.latestEventType);
        } else {
            const relationship = {id: news.relationshipId, partnerPopularNames: news.names};
            actions.log("Relationship=" + relationship.id);
            url = actions.getRelationshipUrl(relationship);
            urlImg = actions.getRelationshipUrlImg(relationship, virtualListWidth, true, news.latestEventType);
        }

        let stylePTag = {};
        if (newsDescriptionHeight > consts.descriptionHeightDefault) {
            stylePTag = {height: newsDescriptionHeight};
        }


        return (
            <div style={style} className="horizontalMenuItem">
                <Link to={url} className="list-group-item link-image">
                    <div className="image-container">
                        <img onError={this.addDefaultSrc}
                             src={urlImg} className="img-fluid horizontalMenuImage"
                             alt={news.description}/>
                        <div className="content">
                            <p style={stylePTag} ref={this.pTag}>{news.description}</p>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        virtualListWidth: state.virtualListWidth,
        newsDescriptionHeight: state.newsDescriptionHeight
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setNewsDescriptionHeight: actions.setNewsDescriptionHeight
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsMenuItem);