import React from 'react';
import * as consts from ".././consts";
import PersonRow from "./PersonRow";
import {connect} from "react-redux";

class PersonTable extends React.Component {

    getPictureSource(){
        const {person} = this.props;
        let photoSource="";
        if(person.flatProfilePictureSource){
            photoSource+=person.flatProfilePictureSource;
        }
        if(person.profilePictureSource && photoSource!=="" && photoSource!==person.profilePictureSource){
            photoSource+=", "+person.profilePictureSource;
        }
        if(photoSource!==""){
            return             <tr>
                <td>Źródło zdjęć</td>
                <td>{photoSource}</td>
            </tr>;
        }else{
            return null;
        }
    }

    render() {
        const {person} = this.props;
        const personAttributes = consts.personAttributes;
        let rows = personAttributes.map(attribute => {
                if(attribute.id==="birthday" && person["birthDate"] && person["birthMonth"] && person["birthYear"]){
                    person.birthday=person["birthDate"]+"-"+person["birthMonth"]+"-"+person["birthYear"];
                }
                if(attribute.id==="deathday" && person["deathDate"] && person["deathMonth"] && person["deathYear"]){
                    person.deathday=person["deathDate"]+"-"+person["deathMonth"]+"-"+person["deathYear"];
                }
                if (person[attribute.id] && person[attribute.id] !== 'UNKNOWN' && person[attribute.id] !== 'OTHER') {
                    return <PersonRow key={attribute.id} person={person}
                                      attribute={attribute}/>
                }else {
                    return null;
                }
            }
        );
        const pictureSource=this.getPictureSource();
        if(pictureSource){
            rows.push(pictureSource);
        }
        return (
            <div className="paddingMain">
                <div className="container">
                    <h3 className="header3">Szczegóły:</h3>
                    <table className="table ">
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        person: state.person
    };
};


export default connect(mapStateToProps)(PersonTable);
