import React from 'react';
import {Link} from "react-router-dom";
import * as actions from "../actions";
import {connect} from "react-redux";
import * as consts from "../consts";

class TableRow extends React.Component {
    constructor(props) {
        super(props);
        this.addDefaultSrc = this.addDefaultSrc.bind(this);
    }

    addDefaultSrc(ev) {
        const {person} = this.props;
        actions.setDefaultUrlImg(person, ev);
    }


    render() {
        const {person, virtualListWidth} = this.props;

        const url = actions.getPersonUrl(person);
        const urlImg = actions.getPersonUrlImg(person, false, virtualListWidth, false);
        let personText = actions.getPersonKnownName(person);
        if(virtualListWidth<=consts.virtualListWidthS){
            personText=<p>{personText}</p>;
        }else{
            personText=<span>{personText}</span>;
        }
        const altImg= actions.getAltImg(personText,person.profilePictureSource);
        return (

            <tr>
                <td>
                    <Link to={url} key={url}>
                        <img onError={this.addDefaultSrc} src={urlImg} className="img-fluid imgThumbnail"
                             alt={altImg}/>
                             {personText}
                    </Link>
                </td>
                <td>{person.scoresLast24h}</td>
                <td>{person.ranking}</td>
                {/*<td>{person.birthday}</td>*/}
            </tr>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        virtualListWidth: state.virtualListWidth
    };
};

export default connect(mapStateToProps)(TableRow);
