import React from 'react';
import Search from "./Search";
import {Link} from "react-router-dom";
import CategoriesMenu from "./CategoriesMenu";
import {bindActionCreators} from "redux";
import * as actions from "../actions";
import {connect} from "react-redux";
import jQuery from 'jquery';


class Top extends React.Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.collapseCategoriesRef=React.createRef();
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        // this.setState({ width: window.innerWidth, height: window.innerHeight });
        const {updateVirtualListWidth} = this.props;
        updateVirtualListWidth(window.window.innerWidth);
    }

    handleClick() {
        const {popupVisible,setPopupVisible} = this.props;
        if (!popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
        setPopupVisible(!popupVisible);
    }

    handleOutsideClick(e) {
        let node=this.collapseCategoriesRef.current;
        jQuery(node).collapse('hide');
        this.handleClick();
    }

    render() {
        const {toggleCategoriesMenu} = this.props;
        return (

            <div>
                <div className="topMenu">
                    <nav className="navbar navbar-expand-sm navbar-light container">

                        {/*mobile h1*/}
                        <h1 className="text-right h1 d-sm-none h1ForMobile">
                            <Link to="/">
                                KT<i className="fas fa-transgender genderIcon gold-icon-color"/> Z KIM RANDKUJE
                            </Link>
                        </h1>
                        {/*Toggler/collapsibe Button*/}
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"/>
                        </button>

                        {/*Navbar links*/}
                        <div className="collapse navbar-collapse justify-content-end pl-2" id="collapsibleNavbar">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/trends">
                                        <span className="navbarTextColor">
                                            <i className="fas fa-chart-line fa-lg"/> Top 24h
                                        </span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/ranking">
                                        <span className="navbarTextColor">
                                            <i className="fas fa-star fa-lg"/> Top 10
                                        </span>
                                    </Link>
                                </li>
                                <li className="nav-item d-none d-lg-block">
                                    <a className="nav-link" data-toggle="collapse" href="#categoriesExpanded"  onClick={this.handleClick}>
                                            <span className="navbarTextColor">
                                                <i className="fas fa-list fa-lg"/> Kategorie
                                            </span>
                                    </a>
                                </li>
                                <li className="nav-item d-lg-none">
                                    <Link className="nav-link" to="#" onClick={toggleCategoriesMenu}>
                                            <span className="navbarTextColor">
                                                <i className="fas fa-list fa-lg"/> Kategorie
                                            </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div id="categoriesExpanded" className="collapse lightBackground categoriesExpanded" ref={this.collapseCategoriesRef}>
                    <div className="container categories">
                        <CategoriesMenu expanded={true}/>
                    </div>
                </div>
                <div className="lightBackground">
                    <div className="headerSection container" >
                        <h1 className="h1 d-none d-sm-block">
                            <Link to="/">
                                KT<i className="fas fa-transgender genderIcon gold-icon-color"/> Z KIM RANDKUJE
                            </Link>
                        </h1>
                    </div>
                </div>


                <div className="lightBackground">
                    <div className="headerSection2 container ">
                        <Search/>
                    </div>
                </div>
            </div>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        categoriesMenuOpen: state.categoriesMenuOpen,
        popupVisible: state.popupVisible
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleCategoriesMenu: actions.toggleCategoriesMenu,
        updateVirtualListWidth: actions.updateVirtualList,
        setPopupVisible: actions.setPopupVisible
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(Top);
