import React from 'react';
import {connect} from "react-redux";
import * as actions from "../actions";
import {Link} from "react-router-dom";

class RelationshipCard extends React.Component {
    constructor(props) {
        super(props);
        this.getRelationshipDescriptionColumns = this.getRelationshipDescriptionColumns.bind(this);
    }

    addDefaultSrc(ev) {
        const {relationship} = this.props;
        this.addDefaultSrc = this.addDefaultSrc.bind(this);
        actions.setDefaultRelationshipUrlImg(relationship, ev);
    }


    getDescriptionColumn(text, value) {
        return <div className="col text-center" key={text}>{text}<br/>{value}</div>;
    }

    getRelationshipDescriptionColumns(person) {
        // const {relationship} = this.props;
        // let columnsArray = [];
        // columnsArray.push(this.getDescriptionColumn("Ranking", person.ranking));
        // if (person.age) {
        //     columnsArray.push(this.getDescriptionColumn("Wiek", person.age));
        // }
        // columnsArray.push(this.getDescriptionColumn("Związki", relationships.length));
        // if (person.zodiac) {
        //     columnsArray.push(this.getDescriptionColumn("Znak zodiaku", person.zodiac));
        // }
        // const columns = columnsArray.map(item => {
        //     return item
        // });
        // return columns;
        return "";
    }

    getPersonLink(person) {
        const url = actions.getPersonUrl(person);
        const knownName = actions.getPersonKnownName(person);
        return <Link to={url} className="nounderline">{knownName}</Link>;
    }

    render() {
        const {relationship, virtualListWidth} = this.props;
        let names = ["", ""];
        if (relationship.persons) {
            names = [this.getPersonLink(relationship.persons[0]), this.getPersonLink(relationship.persons[1])];
        }

        let relationshipText = "";
        if (relationship.persons) {
            relationshipText = actions.getPersonKnownName(relationship.persons[0]) + " i " +
                actions.getPersonKnownName(relationship.persons[1]);
        }
        const altImg=actions.getAltImg(relationshipText,relationship.flatProfilePictureSource);;

        const urlImg = actions.getRelationshipUrlImg(relationship, virtualListWidth, false);
        // const descriptionColumns = this.getRelationshipDescriptionColumns(relationship);
        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="card">
                        {/*<div className="image-container">*/}
                        {/*<img onError={this.addDefaultSrc} src={urlImg} className="img-fluid"*/}
                        {/*alt={relationshipText}/>*/}
                        {/*<div className="content">*/}
                        {/*{names[0]} i {names[1]}*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        <img src={urlImg} alt={altImg} onError={this.addDefaultSrc}/>

                            <p className="text-center relationshipCardBody">{names[0]} i {names[1]}</p>
                            {/*<div className="row">*/}
                            {/*{descriptionColumns}*/}
                            {/*</div>*/}

                    </div>
                </div>
                {/*<div className="col-sm-6 advertisementRelationship">*/}
                {/*    <div className="advertisementText">*/}
                {/*        Reklama*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        relationship: state.relationship,
        virtualListWidth: state.virtualListWidth
    };
};


export default connect(mapStateToProps)(RelationshipCard);
