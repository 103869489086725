import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../actions";
import RelationshipRow from "./RelationshipRow";
import * as consts from "../consts";

class RelationshipTable extends React.Component {


    render() {
        const {relationships,virtualListWidth} = this.props;
        actions.log("render relationship table");
        let rows = relationships.map(relationship => {
                return <RelationshipRow key={relationship.id} relationship={relationship}/>
            }
        );
        let statusTag="";
        if(virtualListWidth>consts.virtualListWidthS){
            statusTag=<th>Status</th>;
        }
        return (
            <div className="paddingMain">
                <div className="container">
                    <h3 className="header3">Związki:</h3>
                    <table className="table">
                        <tbody>
                        <tr>
                            <th>Partner</th>
                            {statusTag}
                            <th>Początek</th>
                            <th>Koniec</th>
                            <th>Więcej</th>
                        </tr>
                        {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        person: state.person,
        relationships: state.relationships,
        virtualListWidth: state.virtualListWidth
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        loadRelationships: actions.loadRelationships
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipTable)
