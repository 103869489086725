import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import * as actions from "../actions";
import * as consts from "../consts";

class RelationshipRow extends React.Component {
    constructor(props) {
        super(props);
        this.getPartner = this.getPartner.bind(this);
        this.getLastStatus = this.getLastStatus.bind(this);
        this.getStartRelationship = this.getStartRelationship.bind(this);
        this.getEndRelationship = this.getEndRelationship.bind(this);
        this.addDefaultSrc = this.addDefaultSrc.bind(this);
    }

    addDefaultSrc(ev) {
        const partner = this.getPartner();
        actions.setDefaultUrlImg(partner, ev);
    }


    getPartner() {
        actions.log("getPartner");
        const {person, relationship} = this.props;
        if (relationship.persons[0].id === person.id) {
            return relationship.persons[1];
        } else {
            return relationship.persons[0];
        }
    }


    getLastStatus() {
        actions.log("getLastStatus");
        let status = "";
        const {relationship} = this.props;
        const periods = relationship.relationshipPeriods;
        if (periods.length > 0) {
            status = actions.getRelationPeriodStatus(periods[0]);
        }
        return status;
    }


    getStartRelationship() {
        actions.log("getStartRelationship");
        let start = "";
        const {relationship} = this.props;
        const periods = relationship.relationshipPeriods;
        if (periods.length > 0) {
            const startPeriod = periods[periods.length - 1];
            start = actions.getDate(startPeriod, true);
        }
        return start;
    }

    getEndRelationship() {
        actions.log("getEndRelationship");
        let end = "";
        const {relationship} = this.props;
        const periods = relationship.relationshipPeriods;
        if (periods.length > 0) {
            const endPeriod = periods[0];
            end = actions.getDate(endPeriod, false);
        }
        return end;
    }


    render() {
        const partner = this.getPartner();
        const status = this.getLastStatus();
        const start = this.getStartRelationship();
        const end = this.getEndRelationship();

        const {relationship, virtualListWidth} = this.props;
        const partnerUrl = actions.getPersonUrl(partner);
        const partnerUrlImg = actions.getPersonUrlImg(partner, false, virtualListWidth, false);
        let partnerName = actions.getPersonKnownName(partner);
        const altImg= actions.getAltImg(partner,partner.profilePictureSource);
        let relationshipDetailsTag="";
        if(status!=="" || start!=="" || end!==""){
            const relationshipDetailsUrl = actions.getRelationshipUrl(relationship);
            relationshipDetailsTag=<Link to={relationshipDetailsUrl}>
                >>
            </Link>;
        }


        let statusTag="";
        if(virtualListWidth>consts.virtualListWidthS){
            statusTag=<td className="align-middle">{status}</td>;
        }
        let partnerNameTag=<p>{partnerName}</p>;
        if(virtualListWidth>consts.virtualListWidthM){
            partnerNameTag=<span>{partnerName}</span>;
        }
        return (
            <tr>
                <td>
                    <Link to={partnerUrl}>
                        <img onError={this.addDefaultSrc}
                            src={partnerUrlImg} className="img-fluid imgThumbnail"
                             alt={altImg}/>
                        {partnerNameTag}
                    </Link>
                </td>
                {statusTag}
                <td className="align-middle">{start}</td>
                <td className="align-middle">{end}</td>
                <td className="align-middle">
                    {relationshipDetailsTag}
                </td>
            </tr>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        person: state.person,
        virtualListWidth: state.virtualListWidth
    };
};

export default connect(mapStateToProps)(RelationshipRow);
