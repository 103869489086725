import React from 'react';
import {connect} from "react-redux";
import * as actions from "../actions";

class RelationshipPeriodRow extends React.Component {
    render() {
        const {period} = this.props;
        let status = actions.getRelationPeriodStatus(period);
        let start = actions.getDate(period, true);
        let end = actions.getDate(period, false);
        return (
            <tr>
                <td>{status}</td>
                <td>{start}</td>
                <td>{end}</td>

            </tr>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        person: state.person
    };
};

export default connect(mapStateToProps)(RelationshipPeriodRow);
