import React from "react";
import {connect} from 'react-redux';
import * as actions from '../actions';
import {bindActionCreators} from 'redux';
import Top from "./Top";
import RelationshipCard from "./RelationshipCard";
import RelationshipPeriodTable from "./RelationshipPeriodTable";
import {Helmet} from "react-helmet";

class RelationshipDetails extends React.Component {

    componentDidMount() {
        const {loadRelationship} = this.props;
        const {id} = this.props.match.params;
        loadRelationship(id);
    }


    render() {
        const {relationship} = this.props;
        let periods = "";
        if (relationship.relationshipPeriods && relationship.relationshipPeriods.length > 0) {
            periods = <RelationshipPeriodTable/>;
        }
        let description="historia związku";
        let keywords = description;
        if (relationship.persons) {
            const firstName = actions.getPersonKnownName(relationship.persons[0]);
            const secondName = actions.getPersonKnownName(relationship.persons[1]);
             description = firstName + " i " + secondName + " - historia związku";
             keywords = description;
            const firstNameLessKnown = actions.getPersonLessKnownName(relationship.persons[0]);
            const secondNameLessKnown = actions.getPersonLessKnownName(relationship.persons[1]);
            if (firstNameLessKnown !== firstName || secondNameLessKnown !== secondName) {
                keywords += firstNameLessKnown + " i " + secondNameLessKnown + " - historia związku";
            }
        }
        return (
            <div>
                <Helmet>
                    <title>{description}</title>
                    <meta name="description" content={description} />
                    <meta name="keywords" content={keywords} />
                </Helmet>
                <Top/>
                <div className="mainPart text-left paddingMain minHeight">
                    <div className="container">
                        <RelationshipCard/>
                        {periods}
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        relationship: state.relationship
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        loadRelationship: actions.loadRelationship
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipDetails);
