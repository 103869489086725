import React from 'react';
import {connect} from "react-redux";
import * as actions from "../actions";

class PersonCard extends React.Component {
    constructor(props) {
        super(props);
        this.getPersonDescriptionColumns = this.getPersonDescriptionColumns.bind(this);
        this.addDefaultSrc = this.addDefaultSrc.bind(this);
    }

    addDefaultSrc(ev) {
        const {person} = this.props;
        actions.setDefaultUrlImg(person, ev, true);
    }


    getDescriptionColumn(text, value, header) {
        if (header) {
            return <div className="col text-center px-0" key={text}>{text}</div>;
        } else {
            return <div className="col text-center px-0" key={text}>{value}</div>;
        }

    }

    getPersonDescriptionColumns(header) {
        const {person, relationships, virtualListWidth} = this.props;
        const numberOfItems = actions.getItemsUnderCard(virtualListWidth);
        let columnsArray = [];
        if (person.age) {
            columnsArray.push(this.getDescriptionColumn("Wiek", person.age, header));
        }
        columnsArray.push(this.getDescriptionColumn("Związki", relationships.length, header));
        if (person.status && columnsArray.length < numberOfItems) {
            columnsArray.push(this.getDescriptionColumn("Etap związku", actions.getStatusText(person.status), header));
        }
        if (person.zodiac && columnsArray.length < numberOfItems) {
            columnsArray.push(this.getDescriptionColumn("Znak", actions.getZodiacText(person.zodiac), header));
        }
        if (columnsArray.length < numberOfItems) {
            columnsArray.push(this.getDescriptionColumn("Ranking", person.ranking, header));
        }
        const columns = columnsArray.map(item => {
            return item
        });
        return columns;
    }

    render() {
        const {person, virtualListWidth} = this.props;

        const urlImg = actions.getPersonUrlImg(person, true, virtualListWidth, false);
        const personText = actions.getPersonKnownName(person);
        const personDescriptionColumns = this.getPersonDescriptionColumns(true);
        const personDescriptionColumnsValues = this.getPersonDescriptionColumns(false);
        const altImg = actions.getAltImg(personText, person.flatProfilePictureSource);
        return (
            <div className="container">
                <div className="row">
                    <div className="card">
                        <div className="image-container">
                            <img src={urlImg} className="img-fluid"
                                 alt={altImg} onError={this.addDefaultSrc}/>
                            <div className="content">
                                <p>{personText}</p>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row pb-1 font-weight-bold">
                                {personDescriptionColumns}
                            </div>
                            <div className="row">
                                {personDescriptionColumnsValues}
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="col-sm-6 advertisement">*/}
                {/*    <div className="advertisementText">*/}
                {/*        Reklama*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        person: state.person,
        relationships: state.relationships,
        virtualListWidth: state.virtualListWidth
    };
};


export default connect(mapStateToProps)(PersonCard);
