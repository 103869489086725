import React from 'react';
import NewsMenuItem from "./NewsMenuItem";
import connect from "react-redux/es/connect/connect";
import VirtualList from 'react-tiny-virtual-list';
import * as actions from "../actions";
import {bindActionCreators} from "redux";

class NewsMenu extends React.Component {

    constructor(props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
    }

    renderItem({index, style}) {
        const {newsForToday} = this.props;
        const news = newsForToday[index];
        return (
            <NewsMenuItem key={news.id} news={news} style={style} index={index}/>
        );

    }


    render() {
        const {newsForToday, virtualListWidth, newsItemSize, newsItemsHeight} = this.props;

        let itemCount = newsForToday.length;
        return (
            <div className="row justify-content-center">
                <VirtualList
                    width={virtualListWidth}
                    height={newsItemsHeight}
                    itemCount={itemCount}
                    itemSize={newsItemSize} // Also supports variable heights (array or function getter)
                    renderItem={this.renderItem}
                    scrollDirection={'horizontal'}
                    scrollOffset={400}
                />
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        virtualListWidth: state.virtualListWidth,
        newsItemSize: state.newsItemSize,
        newsForToday: state.newsForToday,
        newsItemsHeight: state.newsItemsHeight
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateVirtualListWidth: actions.updateVirtualList
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsMenu);
