import React from 'react';
import * as actions from "../actions";
import {connect} from "react-redux";
import * as consts from "../consts";

class TableRow extends React.Component {

    getText(person, attribute) {
        let text = person[attribute.id];
        if (attribute.value) {
            text = actions.getTextFromEnum(text, attribute.value, person.sex);
        }
        return text;
    }


    render() {
        const {person, attribute} = this.props;
        let text = this.getText(person, attribute);
        const {virtualListWidth} = this.props;
        if(attribute.link){
            let aHrefText=text;
            if(virtualListWidth<=consts.virtualListWidthS){
                 let shortenText=text.replace("https://www.instagram.com/", "");
                 shortenText=shortenText.replace("/", "");
                 aHrefText=shortenText;
             }
            text=<a href={text} target="_blank">{aHrefText}</a>;
        }
        return (
            <tr>
                <td>{attribute.name}</td>
                <td>{text}</td>
            </tr>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        virtualListWidth: state.virtualListWidth
    };
};

export default connect(mapStateToProps)(TableRow);
