import React from 'react';

import {BrowserRouter as Router, Link, Route} from 'react-router-dom';

import Home from "./containers/Home";
import Person from "./containers/Person";
import Ranking from "./containers/Ranking";
import Impressum from "./containers/Impressum";
import Trends from "./containers/Trends";
import Categories from "./containers/Categories";
import RelationshipDetails from "./containers/RelationshipDetails";
import CategoriesMenu2 from "./containers/CategoriesMenu2";

class App extends React.Component {


    render() {
        return (
            <div>


                <Router>
                    <div>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/person/:id/:name" component={
                            (props) => (
                                <Person key={props.match.params.id} {...props} />)
                        }/>
                        <Route exact path="/relationship/:id/:name" component={
                            (props) => (
                                <RelationshipDetails key={props.match.params.id} {...props} />)
                        }/>
                        <Route exact path="/ranking" component={Ranking}/>
                        <Route exact path="/trends" component={Trends}/>
                        <Route exact path="/impressum" component={Impressum}/>
                        <Route exact path="/categories/:category/:id" component={(props) => (
                            <Categories key={props.match.params.category + props.match.params.id} {...props} />)
                        }/>
                        <CategoriesMenu2/>
                    </div>
                    <div className="footer blackBackground">
                        <p className="footerContact"><Link to="/impressum" className="text-center">Impressum</Link></p>
                    </div>
                </Router>

            </div>
        )
    }
}


export default App;
