import React from 'react';
import {Link} from 'react-router-dom';
import * as consts from ".././consts";

class CategoriesMenu extends React.Component {

    constructor(props) {
        super(props);
        this.getTags = this.getTags.bind(this);
    }

    getTags(options, name) {
        let tags = options.map(option => {
                const url = "/categories/" + name + "/" + option.id;
                return <li key={url}><Link to={url}>{option.name}</Link></li>
            }
        );
        return tags;
    }

    render() {
        const expanded = this.props.expanded;
        let classNames = "multi-column-dropdown collapse";
        if (expanded) {
            classNames += " show";
        }

        let columns = consts.categoriesMapping.map(category => {
            const href = "#" + category.id;
            const tag = this.getTags(category.value, category.id);
            return <div className="col-sm" key={category.value + category.id}>
                <h5><a href={href} data-toggle="collapse">{category.name}</a></h5>
                <ul id={category.id} className={classNames}>
                    {tag}
                </ul>
            </div>
        });

        return (
            <div className="row categories">
                {columns}
            </div>
        )
    }
}


export default CategoriesMenu;
