import * as consts from "./consts";
import client from "./api/client";

const rootLocal = 'http://localhost:8080';
// const rootLocal = 'http://172.20.10.3:8080';

const rootProduction = 'https://b.ktozkimrandkuje.pl';

export function getRootUrl() {

    if (process.env.NODE_ENV === 'development') {
        return rootLocal;
    } else {
        return rootProduction;
    }
}

export function log(message) {
    // if(process.env.NODE_ENV==='development'){
    //     console.log(message);
    // }
}


// load person

export function loadPerson(personId) {
    return dispatch => {
        client({method: 'GET', path: getRootUrl() + "/getPerson?id=" + personId}).then(data => {

            dispatch(loadPersonDone(data.entity));
        });
    }
}

export function loadPersonDone(data) {
    return {
        type: consts.LOAD_PERSON,
        payload: data
    };
}


// load relationships
export function loadRelationships(personId) {
    return dispatch => {
        client({method: 'GET', path: getRootUrl() + "/getRelationships?id=" + personId}).then(data => {

            dispatch(loadRelationshipsDone(data.entity));
        });
    }
}

export function loadRelationshipsDone(data) {
    return {
        type: consts.LOAD_RELATIONSHIPS,
        payload: data
    };
}


//LOAD_RELATIONSHIP
export function loadRelationship(relationshipId) {
    return dispatch => {
        client({method: 'GET', path: getRootUrl() + "/getRelationship?id=" + relationshipId}).then(data => {
            dispatch(loadRelationshipDone(data.entity));
        });
    }
}

export function loadRelationshipDone(data) {
    return {
        type: consts.LOAD_RELATIONSHIP,
        payload: data
    };
}

// desktop categories

export function setPopupVisibleDone(data) {
    return {
        type: consts.SET_POPUP_VISIBLE,
        payload: data
    };
}

export function setPopupVisible(visible) {
    return dispatch => {
        dispatch(setPopupVisibleDone(visible));
    }
}

// ranking top

export function loadRankingTop() {
    const size = 10;
    return dispatch => {
        client({
            method: 'GET',
            path: getRootUrl() + "/api/persons/search/getPersons?sort=ranking&size=" + size,
        }).then(data => {
            dispatch(loadRankingTopDone(data.entity._embedded.persons));
        });
    }
}

export function loadRankingTopDone(data) {
    return {
        type: consts.LOAD_RANKING_TOP,
        payload: data
    };
}

// trends top

export function loadTrendsTop() {
    return dispatch => {
        client({
            method: 'GET',
            path: getRootUrl() + "/api/persons/search/getPersons?sort=scoresLast24h,desc&size=13",
        }).then(data => {
            dispatch(loadTrendsTopDone(data.entity._embedded.persons));
        });
    }
}

export function loadTrendsTopDone(data) {
    return {
        type: consts.LOAD_TRENDS_TOP,
        payload: data
    };
}

// trends

export function loadTrends(page) {
    return dispatch => {
        client({
            method: 'GET',
            path: getRootUrl() + "/api/persons/search/getPersons?sort=scoresLast24h,desc&size=15&page=" + page,
        }).then(data => {
            dispatch(loadTrendsDone(data.entity._embedded.persons));
            if (data.entity._embedded.persons.length === 0) {
                dispatch(loadTrendsMoreDone(false));
            }
        });
    }
}

export function loadTrendsDone(data) {
    return {
        type: consts.LOAD_TRENDS,
        payload: data
    };
}

export function loadTrendsMoreDone(data) {
    return {
        type: consts.SET_TRENDS_MORE,
        payload: data
    };
}

// ranking

export function loadRanking(page) {
    return dispatch => {
        client({
            method: 'GET',
            path: getRootUrl() + "/api/persons/search/getPersons?sort=ranking,asc&size=15&page=" + page,
        }).then(data => {
            dispatch(loadRankingDone(data.entity._embedded.persons));
            if (data.entity._embedded.persons.length === 0) {
                dispatch(loadRankingMoreDone(false));
            }
        });
    }
}

export function loadRankingDone(data) {
    return {
        type: consts.LOAD_RANKING,
        payload: data
    };
}

export function loadRankingMoreDone(data) {
    return {
        type: consts.SET_RANKING_MORE,
        payload: data
    };
}

// latest events
export function setNewsForTodayDone(data) {
    return {
        type: consts.SET_NEWS_FOR_TODAY,
        payload: data
    };
}

export function setNewsForToday() {
    const size = 10;
    return dispatch => {
        client({
            method: 'GET',
            path: getRootUrl() + "/api/latestEvents/search/getNews?size=" + size,
        }).then(data => {
            dispatch(setNewsForTodayDone(data.entity._embedded.latestEvents));
        });
    }
}

// personsByCategory

export function loadByCategory(page, category, value) {
    let categoryUrl = getCategoryUrl(category, value);
    return dispatch => {
        client({
            method: 'GET',
            path: getRootUrl() + '/api' + categoryUrl + "&size=15&page=" + page,
        }).then(data => {
            dispatch(loadByCategoryDone(data.entity._embedded.persons));
            if (data.entity._embedded.persons.length === 0) {
                dispatch(loadByCategoryMoreDone(false));
            }
        });
    }
}


export function loadByCategoryDone(data) {
    return {
        type: consts.LOAD_BY_CATEGORY,
        payload: data
    };
}

export function loadByCategoryMoreDone(data) {
    return {
        type: consts.SET_BY_CATEGORY_MORE,
        payload: data
    };
}

export function clearByCategoryDone(data) {
    return {
        type: consts.CLEAR_BY_CATEGORY,
        payload: data
    };
}

export function clearCategories() {
    return dispatch => {
        dispatch(clearByCategoryDone([]));
        dispatch(loadByCategoryMoreDone(true));
    }
}

export function setLastCategoryDone(data) {
    return {
        type: consts.SET_LAST_CATEGORY,
        payload: data
    };
}

export function setLastCategory(data) {
    return dispatch => {
        dispatch(setLastCategoryDone(data));
    }
}

export function getCategoryUrl(category, value) {
    if (category === 'eyeColor') {
        return "/persons/search/getPersonsByEyeColor?eyeColor=" + value;
    } else if (category === 'hairColor') {
        return "/persons/search/getPersonsByHairColor?hairColor=" + value;
    } else if (category === 'zodiac') {
        return "/persons/search/getPersonsByZodiac?zodiac=" + value;
    } else if (category === 'sex') {
        return "/persons/search/getPersonsBySex?sex=" + value;
    } else if (category === 'country') {
        return "/persons/search/getPersonsByCountry?countryCode=" + value;
    } else if (category === 'age') {
        let end = parseInt(value) + 9;
        if (value === 100) {
            end += 50;
        }
        return "/persons/search/getPersonsByAge?start=" + value + "&end=" + end;
    } else if (category === 'status') {
        return "/persons/search/getPersonsByStatus?status=" + value;
    }

}

export function toggleCategoriesMenu() {
    return dispatch => {
        dispatch(toggleCategoriesMenuDone());
    }
}

export function toggleCategoriesMenuDone() {
    return {
        type: consts.SET_CATEGORIES_MENU_OPEN,
        payload: {}
    };
}


// search suggestions

export function clearSearchSuggestionsDone() {
    return {
        type: consts.LOAD_SEARCH_SUGGESTIONS,
        payload: []
    };
}

export function clearSearchSuggestions() {
    return dispatch => {
        dispatch(clearSearchSuggestionsDone());
        dispatch(setSearchValueDone(""));
    }
}

export function setSearchValueDone(newValue) {
    return {
        type: consts.SET_SEARCH_VALUE,
        payload: newValue
    };
}

export function setSearchValue(event, {newValue, method}) {
    return dispatch => {
        dispatch(setSearchValueDone(newValue));
    }
}

export function getSearchSuggestionsDone(data) {
    return {
        type: consts.LOAD_SEARCH_SUGGESTIONS,
        payload: data
    };
}

export function getSearchSuggestions({value, reason}) {
    const valuesTab = value.split(" ");
    const firstName = valuesTab[0];
    let lastName = valuesTab[0];
    if (valuesTab.length > 1) {
        lastName = valuesTab[1];
    }
    const pathStr = getRootUrl() + "/api/persons/search/getSearchSuggestions?firstName=" + firstName + "&lastName=" + lastName + "&nickName=" + value;
    return dispatch => {
        client({
            method: 'GET',
            path: pathStr,
        }).then(data => {
            dispatch(getSearchSuggestionsDone(data.entity._embedded.persons));
        });
    }
}


// utils

export function getDate(period, start) {
    let date = "";
    let day = "endDay";
    let month = "endMonth";
    let year = "endYear";
    if (start) {
        day = "startDay";
        month = "startMonth";
        year = "startYear";
    }
    if (period[day]) {
        if (period[day] < 10) {
            date += "0";
        }
        date += period[day] + "-";
    }
    if (period[month]) {
        if (period[month] < 10) {
            date += "0";
        }
        date += period[month] + "-";
    }
    if (period[year]) {
        date += period[year];
    }
    return date;
}

export function getRelationPeriodStatus(period) {
    let result = "";
    if (period.type) {
        const status = period.type;
        result = getStatusText(status);
    }
    return result;
}


export function getPersonUrl(person) {
    const personUrl = "/person/" + person.id + "/" + person.popularName;
    if (!person.popularName) {
        log("getPersonUrl=" + personUrl);
    }
    return personUrl;
}

export function getAltImg(defaultText, photoSource) {
    let alt = defaultText;
    if (photoSource) {
        alt += ", zdjęcie: " + photoSource;
    }
    return alt;
}

export function getPersonUrlImg(person, flat, virtualListWidth, horizontalMenu, index, eventType) {
    let url = getRootUrl() + "/photos/" + person.id + "/";
    if (flat && person.flatProfilePicture) {
        if (virtualListWidth === consts.virtualListWidthL) {
            url += "flatProfileL.jpg";
        } else if (virtualListWidth === consts.virtualListWidthM) {
            url += "flatProfileM.jpg";
        } else if (virtualListWidth === consts.virtualListWidthS) {
            url += "flatProfileS.jpg";
        } else {
            if (horizontalMenu) {
                url += "flatProfileS.jpg";
            } else {
                url += "flatProfileL.jpg";
            }
        }
    } else if (person.profilePicture) {
        if (horizontalMenu) {
            if (virtualListWidth === consts.virtualListWidthL) {
                url += "profileM.jpg";
            } else {
                url += "profileS.jpg";
            }
        } else {
            if (virtualListWidth === consts.virtualListWidthL) {
                url += "profileL.jpg";
            } else if (virtualListWidth === consts.virtualListWidthM) {
                url += "profileM.jpg";
            } else {
                url += "profileS.jpg";
            }
        }
    } else {
        url = getDefaultUrlImg(person, flat, index, eventType);
    }
    // log("Flat=" + flat + ", virtualListWidth=" + virtualListWidth + ", horizontalMenu=" + horizontalMenu + ", url=" + url);
    return url;
}

export function getPersonKnownName(person) {
    let name = person.name;
    if (person.nickNamePrio && person.nickName) {
        name = person.nickName;
    }
    return name;
}

export function cutPersonName(name, length) {
    let result = name;
    if (name.length > length) {
        let index = name.lastIndexOf('-');
        if (index === -1) {
            index = name.lastIndexOf(' ');
        }
        if (index !== -1) {
            result = name.substring(0, index);
        }
    }
    return result;
}

export function getPersonLessKnownName(person) {
    let name = person.name;
    if (person.nickNamePrio === false && person.nickName) {
        name = person.nickName;
    }
    return name;
}

export function getNames(person) {
    let name = person.name;
    if (person.nickName) {
        name += ' ' + person.nickName;
    }
    return name;
}

export function getDefaultUrlImg(person, flat, index, eventType) {
    let result = "";
    if (eventType) {
        if (eventType === 'BIRTHDAY') {
            const i = index % 4;
            result = "/d/birthday" + i + ".jpg";
        } else if (eventType === 'BIRTHDAY_ANNIVERSARY') {
            result = "/d/birthdayAnniversary.jpg";
        } else if (eventType === 'DEATHDAY') {
            result = "/d/deathday.jpg";
        }
    } else if (person.sex && person.sex === 'FEMALE') {
        if (flat) {
            result = "/d/flat-women.jpg";
        } else {
            result = "/d/women.jpg";
        }
    } else {
        if (flat) {
            result = "/d/flat-men.jpg";
        } else {
            result = "/d/men.jpg";
        }
    }
    result += "?id=" + person.id;
    return result;
}

export function setDefaultUrlImg(person, ev, flat, index, eventType) {
    ev.target.src = getDefaultUrlImg(person, flat, index, eventType);
}

export function getRelationshipUrl(relationship) {
    const names = relationship.partnerPopularNames;
    const relationshipUrl = "/relationship/" + relationship.id + "/" + names;
    return relationshipUrl;
}

export function setDefaultRelationshipUrlImg(relationship, ev, eventType) {
    ev.target.src = getDefaultRelationshipUrlImg(relationship, eventType);
}

export function getRelationshipUrlImg(relationship, virtualListWidth, horizontalMenu, eventType) {
    let url;
    if (relationship.flatProfilePicture) {
        url = getRootUrl() + "/photos/relationship/" + relationship.id + "/";
        if (virtualListWidth === consts.virtualListWidthL) {
            url += "flatProfileL.jpg";
        } else if (virtualListWidth === consts.virtualListWidthM) {
            url += "flatProfileM.jpg";
        } else if (virtualListWidth === consts.virtualListWidthS) {
            url += "flatProfileS.jpg";
        } else {
            if (horizontalMenu) {
                url += "flatProfileS.jpg";
            } else {
                url += "flatProfileL.jpg";
            }
        }
    } else {
        url = getDefaultRelationshipUrlImg(relationship, eventType);
    }
    return url;
}

export function getDefaultRelationshipUrlImg(relationship, eventType) {
    let url = "/d/relationship.jpg";
    if (eventType) {
        if (eventType === 'WEDDING_ANNIVERSARY') {
            url = "/d/weddingAnniversary.jpg";
        } else if (eventType === 'ENGAGEMENT_ANNIVERSARY') {
            url = "/d/engagementAnniversary.jpg";
        } else if (eventType === 'ANNIVERSARY') {
            url = "/d/anniversary.jpg";
        } else if (eventType === 'DIVORCE_ANNIVERSARY') {
            url = "/d/divorceAnniversary.jpg";
        }
    }
    if (relationship) {
        url += "?id=" + relationship.id;
    }
    return url;
}

export function getTextFromEnum(enumValue, enumMapping, sex) {
    for (var i = 0; i < enumMapping.length; i++) {
        if (enumMapping[i].id === enumValue) {
            if (sex === 'FEMALE' && enumMapping[i].femaleName) {
                return enumMapping[i].femaleName;
            } else {
                return enumMapping[i].name;
            }
        }
    }
    return enumValue;
}

export function getZodiacText(enumValue) {
    const zodiac = consts.zodiac;
    return getTextFromEnum(enumValue, zodiac, false);
}

export function getStatusText(enumValue) {
    const status = consts.status;
    return getTextFromEnum(enumValue, status, false);
}

// horizontal list

export function updateVirtualList(width) {
    let containerWidth = 960;
    let itemSizeRatio = 4.2;
    let itemSize;
    let newsItemSize;
    let newsItemsHeight;


    if (width >= 992) {
        containerWidth = consts.virtualListWidthL;
    } else if (width >= 768) {
        containerWidth = consts.virtualListWidthM;
    } else if (width >= 576) {
        containerWidth = consts.virtualListWidthS;
        itemSizeRatio = 3.2;
    } else {
        containerWidth = width;
        itemSizeRatio = 3.2;
    }
    itemSize = containerWidth / itemSizeRatio;

    newsItemSize = containerWidth / 2.2;
    newsItemsHeight = newsItemSize * 2 / 3;
    newsItemsHeight += 10;

    return dispatch => {
        dispatch(updateVirtualListWidthDone(containerWidth));
        dispatch(updateVirtualListItemSize(itemSize));
        dispatch(updateNewsItemSize(newsItemSize));
        dispatch(updateNewsItemsHeight(newsItemsHeight));
    }
}


export function updateNewsItemSize(data) {
    return {
        type: consts.SET_NEWS_ITEM_SIZE,
        payload: data
    };
}

export function updateNewsItemsHeight(data) {
    return {
        type: consts.SET_NEWS_ITEMS_HEIGHT,
        payload: data
    };
}

export function updateVirtualListWidthDone(data) {
    return {
        type: consts.SET_VIRTUAL_LIST_WIDTH,
        payload: data
    };
}

export function updateVirtualListItemSize(data) {
    // log("updateVirtualListItemSize=" + data);
    return {
        type: consts.SET_VIRTUAL_ITEM_SIZE,
        payload: data
    };
}


export function setNewsDescriptionHeight(height) {
    return dispatch => {
        dispatch(setNewsDescriptionHeightDone(height));
    }
}

export function setNewsDescriptionHeightDone(data) {
    return {
        type: consts.SET_NEWS_DESCRIPTION_HEIGHT,
        payload: data
    };
}

export function setHorizontalMenuDescriptionHeight(height) {
    return dispatch => {
        dispatch(setHorizontalMenuDescriptionHeightDone(height));
    }
}

export function setHorizontalMenuDescriptionHeightDone(data) {
    return {
        type: consts.SET_HORIZONTAL_MENU_DESCRIPTION_HEIGHT,
        payload: data
    };
}

export function setPodiumDescriptionHeight(height) {
    return dispatch => {
        dispatch(setPodiumDescriptionHeightDone(height));
    }
}

export function setPodiumDescriptionHeightDone(data) {
    return {
        type: consts.SET_PODIUM_DESCRIPTION_HEIGHT,
        payload: data
    };
}

// card

export function getItemsUnderCard(virtualListWidth) {
    let number = 0;
    if (virtualListWidth === consts.virtualListWidthL) {
        number = 5;
    } else if (virtualListWidth === consts.virtualListWidthM) {
        number = 5;
    } else if (virtualListWidth === consts.virtualListWidthS) {
        number = 5;
    } else {
        if (virtualListWidth > 470) {
            number = 5;
        } else if (virtualListWidth > 370) {
            number = 4;
        } else if (virtualListWidth > 200) {
            number = 3;
        } else {
            number = 2;
        }

    }
    // log("getItemsUnderCard=" + number);
    return number;
}