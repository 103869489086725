import React from "react";
import {bindActionCreators} from "redux";
import * as actions from "../actions";
import connect from "react-redux/es/connect/connect";
import Autosuggest from "react-autosuggest";
import SearchRow from "./SearchRow";

// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (

    <SearchRow person={suggestion}/>
);

class Search extends React.Component {

    // componentDidMount(){
    //     const{loadTrendsTop}=this.props;
    // }


    render() {
        const {searchSuggestions, clearSearchSuggestions, getSearchSuggestions, setSearchValue, searchValue} = this.props;
        const inputProps = {
            placeholder: '',
            value: searchValue,
            onChange: setSearchValue
        };
        return (
            <div className="text-center">
                <div className="text-center searchBox  ">
                    <Autosuggest suggestions={searchSuggestions}
                                 onSuggestionsFetchRequested={getSearchSuggestions}
                                 onSuggestionsClearRequested={clearSearchSuggestions}
                                 getSuggestionValue={getSuggestionValue}
                                 renderSuggestion={renderSuggestion}
                                 inputProps={inputProps}/>

                </div>
                <span className="searchLoop"> <i className="fa fa-search fa-lg"></i></span>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        searchSuggestions: state.searchSuggestions,
        searchValue: state.searchValue
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        clearSearchSuggestions: actions.clearSearchSuggestions,
        getSearchSuggestions: actions.getSearchSuggestions,
        setSearchValue: actions.setSearchValue
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);