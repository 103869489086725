import React from "react";
import {connect} from 'react-redux';
import * as actions from '../actions';
import {bindActionCreators} from 'redux';
import Top from "./Top";
import PersonTable from "./PersonTable";
import RelationshipTable from "./RelationshipTable";
import PersonCard from "./PersonCard";
import {Helmet} from "react-helmet";

class Person extends React.Component {


    componentDidMount() {
        const {loadPerson, loadRelationships} = this.props;
        const {id} = this.props.match.params;
        loadPerson(id);
        loadRelationships(id);
    }

    render() {
        const {person, relationships} = this.props;
        let personContent = "";
        let relationshipsContent = "";
        let personCard = "";
        actions.log("person check");
        if (person.privatePerson === false) {
            personContent = <PersonTable/>
            if (relationships.length > 0) {
                relationshipsContent = <RelationshipTable/>
            }
            personCard = <PersonCard/>;
        }
        const description=actions.getNames(person)+" - historia związków";
        let keywords=person.name+' historia związków, '+person.name+' historia małżeństw';
        if(person.nickName){
            keywords+=person.nickName+' historia związków, '+person.nickName+' historia małżeństw';
        }
        return (
            <div>
                <Helmet>
                    <title>{ `${actions.getPersonKnownName(person)} - historia związków` }</title>
                    <meta name="description" content={description} />
                    <meta name="keywords" content={keywords} />
                </Helmet>
                <Top/>
                <div className="mainPart text-left paddingMain">
                        {personCard}
                        {relationshipsContent}
                        {personContent}
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        person: state.person,
        relationships: state.relationships
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        loadPerson: actions.loadPerson,
        loadRelationships: actions.loadRelationships
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Person);
